import amplitude from 'amplitude-js';
import { Page } from "./AnalyticsEnum";
import { Config } from './Config';
import { Environment } from './Environment';
class Analytics {

  init() {
    amplitude.getInstance().init("67fad179f394c156de06f8311eb4cf5b", undefined, {
      disableCookies: true
    });
  }

  setUserId(userId: string) {
    amplitude.getInstance().setUserId(userId);
    const isProdEnv = Config.getInstance().getEnvironment() === Environment.PRODUCTION;
    amplitude.getInstance().setUserProperties({ isProd: isProdEnv })
  }

  clearUser() {
    amplitude.getInstance().setUserId(null);
  }

  logPageView(page: Page) {
    amplitude.getInstance().logEvent("PageView", { page });
  }

  logError(status: number, errorMessage: string, endpoint: string) {
    amplitude.getInstance().logEvent("ErrorLog", { code: status, error: errorMessage, path: endpoint });
  }
}

export const analytics = new Analytics();
