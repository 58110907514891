// ** React Imports
import { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import * as serviceWorker from './serviceWorker'
import { analytics } from './utility/Analytics';
import "./views/toastify.css";
import { CircularProgress } from '@mui/material';
import style from "./index.module.css";
const LazyApp = lazy(() => import('./App'));

const CloseButton = () => (
  <CancelOutlinedIcon className={style.toasterCloseBtn} />
);

analytics.init();
ReactDOM.render(
  <Suspense fallback={<div className={style.spinnerContainer}><CircularProgress /></div>}>
    <LazyApp />
    <ToastContainer newestOnTop closeButton={CloseButton} />
  </Suspense>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
