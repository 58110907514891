import { Environment } from "./Environment";

export class Config {
    private static instance: Config;
    private environment: Environment;

    private constructor() {
        const reactEnv = process.env.REACT_APP_ENV as "devLocal" | "devLocalBuild" | "dev" | "stage" | "prod" | undefined;
        switch (reactEnv) {
            case "devLocal":
                this.environment = Environment.DEV_LOCAL;
                break;
            case "devLocalBuild":
                this.environment = Environment.DEV_LOCAL_BUILD;
                break;
            case "dev":
                this.environment = Environment.DEV;
                break;
            case "stage":
                this.environment = Environment.STAGE;
                break;
            case "prod":
                this.environment = Environment.PRODUCTION;
                break;
            default:
                this.environment = Environment.DEV;
                break;
        }
    }

    public static getInstance() {
        if (!Config.instance) {
            Config.instance = new Config();
        }
        return Config.instance;
    }

    public getHost() {
        switch (this.environment) {
            case Environment.DEV_LOCAL:
                return "http://localhost:3000";

            case Environment.DEV_LOCAL_BUILD:
                return "http://localhost:8080";

            case Environment.DEV:
            case Environment.STAGE:
                return "https://apistage.app.fintheon.com";

            case Environment.PRODUCTION:
                return "https://api.app.fintheon.com";
        }
    }

    public getEnvironment() {
        return this.environment;
    }
}
